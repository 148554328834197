/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
* {
  font:1em "Fira Sans", sans-serif;
}
/* .text-grand, .text-grand *{
  font: 1.2em "Fira Sans", sans-serif;
} */

.text-nn {
  font-size:0.8em;
}
.text-sm{
  font-size:0.9em;
  padding-left: 2px;
}
.text-2{
  font-size:0.8em;
}

.wmax100{width: 100%;}
.minicoindroit{
  /* width:min-content; */
  position:absolute;
  top:2px;
  right:2px;
  font-size: 0.6em;
  width:fit-content;
  text-align:right;
}

.modal{
  position:absolute;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1000;
}
.modalClose{
  position:absolute;
  right:10px;
  top:10px;
}

.piedpage{
  position:sticky;
  left:0;
  bottom: 0;
  width: 100%;
  /* font-size: 18px; */
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
}

.filterMots span{
  font-size: 18px;
}
.pastilleCouleur span{
  color:white;
  mix-blend-mode: multiply;
}
.pastilleEquipe{
  user-select: none;
}
.stickyBG{
  position: absolute;
  bottom:20px;
  left:20px;
}

.contCent{
  display: flex;justify-content: center;align-items: center;width:100%;height:100%;
}
.contCent p{text-align: center;}
.fermetureDrop{
  position: absolute;
  top:5px;
  right: 5px;
}
.text-retract{
  /* text-fit:contain; */
  font-size: min(0.3em, 5vw, 20px);
}
.notificationGrillee{
  position:fixed;
  top:5px;
  right:5px;
  z-index:98765
}
.modal-body-overflow {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.modal-max-h {
  /* max-height: 95vh;
  min-height: 95vh; */
  height:95vh;
}
.swiper-slide {
  
  color: #fff;
}

.nopad{
  padding: 0px !important;
}
.devanttout{
  z-index:9999;
  position:absolute;
}
/* .sectionAppel{
  height: 92%;
} */
.boutonfiltreappel{
  position: fixed;
  top:5px;
  right: 5px;
  z-index:1045;
}
.sectionAppel > .tab-content{
  height: 75vh;
  overflow-y: scroll;
}
.screenMarron{
  background-color:#a3aa9a;
  position:absolute;
  width:100%;
  height: 100%;

}
.liennorm {
  color: inherit;
  text-decoration: none;
}

.liennorm:hover,
.liennorm:focus {
  color: inherit;
  text-decoration: none;
}

.textecartemot{
  font-weight: bold;
  font-size: 1.3em;
}

.fullhauteur{
  height:100%;
  background-color: #5ab5c4;
}
.fulllargeur{
  width:100%;
}
.centrer{
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-blanc{
  color:white !important;
}

.tournoi_scroll{
  height: 70vh;
  /* width:80%; */
  overflow-y: scroll;
}
.demandeacces{
  position:absolute;
  bottom:-200px;
}
/* .nav-tabs .nav-link.active{
  color:#5ab5c4 !important;
}
.nav-link {
  color:#5ab5c4 ;
} */